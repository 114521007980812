const displayConditions = {
  formWithPreFilledData: {
    isURL: ["link", "sourceURLObj"],
    isBoolean: [
      "isDefaultPlaylist",
      "isFeaturedPlaylist",
      "isDefaultService",
      "active",
      "addToNavMenu",
    ],
    isDate: ["releaseDate", "createdAt", "updatedAt"],
    richTextEditorNeeded: ["text", "textTwo", "textThree", "textFour"],
    protectedHidden: [
      "title",
      "slug",
      "identifier",
      "masterLibraryID",
      "_id",
      "iframeCustomAttributes",
    ],
    protectedVisible: [
      "PROTECT-ALL",
      "title",
      "releaseDate",
      "createdAt",
      "updatedAt",
      "slug",
      "identifier",
      "masterLibraryID",
    ],
  },
  emptyForm: {
    isURL: ["link", "sourceURLObj"],
    isBoolean: [
      "isDefaultPlaylist",
      "isDefaultService",
      "isFeaturedPlaylist",
      "active",
      "addToNavMenu",
    ],
    isDate: ["releaseDate", "createdAt", "updatedAt"],
    richTextEditorNeeded: ["text", "textTwo", "textThree", "textFour"],
    protectedHidden: ["identifier"],
    protectedVisible: [],
  },
};

export default displayConditions;
