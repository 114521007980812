const useItemIsUsed = () => {
  const outputFunction = (string) => {
    if (typeof string !== "string") return false;
    const fullSanitizedTextForTest = string.replace(/(<([^>]+)>)/gi, "");
    return fullSanitizedTextForTest.replaceAll(" ", "").length > 0;
  };

  return outputFunction;
};

export default useItemIsUsed;
