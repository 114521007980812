import { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import styles from "./CatalogItem.module.css";
import { formInputDataActions } from "../../../store/formInputDataSlice";
import Editor from "../../../UI/Form/Editor/Editor";
// import quillEmoji from "quill-emoji";
// import "react-quill/dist/quill.snow.css";
// import "quill-emoji/dist/quill-emoji.css";

const CatalogItem = (props) => {
  const catalogItemsObj = props.catalogItemsObj.catalogItemsObj;

  const showProtectedHidden = props.showProtectedHidden;
  const unlockProtectedVisible = props.unlockProtectedVisible;
  const displayConditions = props.displayConditions;
  const onlyList = props.onlyList;
  // const key = catalogItemsObj._id;
  const key = props.passedKey;
  // const setExistingFormInputValuesObj = props.setExistingFormInputValuesObj;
  const parentKey = props.parentKey;
  const parentsParentKey = props.parentsParentKey;
  const parentMasterID = props.parentMasterID;
  const section = props.section;
  const emptyForm = props.emptyForm;
  const [editedField, setEditedField] = useState(false);
  const dispatch = useDispatch();
  const [urlNotValid, setUrlNotValid] = useState(false);
  // const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

  // Quill.register(
  //   {
  //     "formats/emoji": EmojiBlot,
  //     "modules/emoji-shortname": ShortNameEmoji,
  //     "modules/emoji-toolbar": ToolbarEmoji,
  //     "modules/emoji-textarea": TextAreaEmoji,
  //   },
  //   true
  // );

  // const modulesQuill = {
  //   toolbar: {
  //     container: [
  //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //       [{ font: [] }, { size: [] }],
  //       [{ align: [] }],
  //       ["bold", "italic", "underline", "strike"],
  //       [{ script: "sub" }, { script: "super" }], // superscript/subscript
  //       [{ indent: "-1" }, { indent: "+1" }],
  //       [
  //         { list: "ordered" },
  //         { list: "bullet" },
  //         {
  //           color: colorOptions,
  //         },
  //         {
  //           background: colorOptions,
  //         },

  //         ["link", "image", "video"],
  //         "emoji",
  //         "clean",
  //       ],
  //     ],
  //   },
  //   "emoji-toolbar": true,
  //   "emoji-textarea": true,
  //   "emoji-shortname": true,
  // };

  const checkIfUseThisElement = (target) => {
    return (
      displayConditions &&
      Object.hasOwn(displayConditions, target) &&
      displayConditions[target].includes(key)
    );
  };

  const addInputData = (e) => {
    if (typeof e === "string") return;
    e.preventDefault();
    const type = e.target.type;
    let parentMasterID = e.target.getAttribute("data-parentmasterid");
    let parentKey = e.target.getAttribute("data-parentkey");
    const section = e.target.getAttribute("data-section");
    let title = e.target.getAttribute("title");
    let outputValue = e.target.value.trim();

    const urlValidationRegex = new RegExp(
      // eslint-disable-next-line
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    );
    if (parentKey === "sourceURLObj" || type === "url") {
      if (!urlValidationRegex.test(outputValue)) {
        setUrlNotValid(true);
      } else {
        setUrlNotValid(false);
      }
    }
    if (parentMasterID !== parentKey) {
      if (parentKey === "sourceURLObj") {
        outputValue = { [title]: outputValue };
        title = parentKey;
        // parentKey = parentsParentKey;
      }
      //   else {
      //     outputValue = { [title]: outputValue };
      //     title = parentKey;
      //   }
    }

    if (emptyForm)
      dispatch(
        formInputDataActions.addToNewFormInputDataObj({
          section: section,
          formNumber: parentMasterID,
          fieldName: title,
          value: outputValue,
        })
      );

    if (!emptyForm)
      dispatch(
        formInputDataActions.addToExistingFormInputDataObj({
          parentMasterID,
          title,
          outputValue,
        })
      );
    setEditedField(true);
  };

  const output = (
    <li
      key={key}
      data-marker="CATALOG-ITEM"
      id={
        parentMasterID +
        "-" +
        parentsParentKey +
        "-" +
        parentKey +
        "-" +
        key +
        "-" +
        "item"
      }
      data-section={section}
      className={
        styles.item +
        " " +
        styles[
          "protectedHidden-" +
            (displayConditions &&
              Object.hasOwn(displayConditions, "protectedHidden") &&
              displayConditions.protectedHidden.includes(key) &&
              !showProtectedHidden.includes(parentMasterID))
        ] +
        " " +
        styles[parentKey] +
        " " +
        styles[parentKey + "-" + key] +
        " " +
        styles[key] +
        " " +
        (editedField && !emptyForm && styles["edited-field"]) +
        " " +
        (emptyForm && styles["new-form-item"]) +
        " " +
        (key === "active" &&
          (catalogItemsObj[key] && catalogItemsObj[key] !== "false"
            ? styles["is-active"]
            : styles["not-active"]))
      }
    >
      {key === "_id" &&
        !onlyList &&
        displayConditions &&
        checkIfUseThisElement("protectedHidden") && (
          <h4
            name={parentKey + "-" + key}
            className={
              styles[
                "protectedHidden-" +
                  (displayConditions &&
                    Object.hasOwn(displayConditions, "protectedHidden") &&
                    displayConditions.protectedHidden.includes(key) &&
                    !showProtectedHidden.includes(parentMasterID))
              ] +
              " " +
              styles["_id"]
            }
          >
            {catalogItemsObj[key]}
          </h4>
        )}

      {key !== "_id" &&
        !onlyList &&
        displayConditions &&
        Object.hasOwn(displayConditions, "isBoolean") &&
        !displayConditions.isBoolean.includes(key) &&
        !displayConditions.isDate.includes(key) &&
        !displayConditions.isURL.includes(key) && (
          <Fragment>
            <label
              id={
                parentMasterID +
                "-" +
                parentsParentKey +
                "-" +
                parentKey +
                "-" +
                key +
                "label"
              }
              htmlFor={parentKey + "-" + key}
              data-section={section}
              className={
                styles[
                  "protectedVisible-" +
                    (displayConditions.protectedVisible.includes(key) &&
                      !unlockProtectedVisible.includes(parentMasterID))
                ]
              }
            >
              {key}:
            </label>
            {section === "catalog" && key === "type" && (
              <p className={styles["label-support-text"]}>
                Examples: song, playlist, album or service. Can be anything.
              </p>
            )}

            {section === "content" && key === "type" && (
              <p className={styles["label-support-text"]}>
                Supported content: about, noticeOne, noticeTwo, noticeThree and
                socialConnection.
              </p>
            )}
            {!checkIfUseThisElement("richTextEditorNeeded") && (
              <textarea
                theme="snow"
                id={
                  parentMasterID +
                  "-" +
                  parentsParentKey +
                  "-" +
                  parentKey +
                  "-" +
                  key +
                  "textarea"
                }
                key={parentKey + "-" + key}
                name={parentKey + "-" + key}
                data-category={catalogItemsObj[key]}
                placeholder={key}
                title={key}
                data-section={section}
                data-parentkey={parentKey}
                data-parentsparentkey={parentsParentKey ? parentsParentKey : ""}
                data-parentmasterid={parentMasterID}
                defaultValue={catalogItemsObj[key]}
                onChange={addInputData}
                className={
                  styles[
                    "protectedVisible-" +
                      (displayConditions.protectedVisible.includes(
                        "PROTECT-ALL"
                      ) && !unlockProtectedVisible.includes(parentMasterID)) ||
                      (displayConditions &&
                        Object.hasOwn(displayConditions, "protectedVisible") &&
                        displayConditions.protectedVisible.includes(key) &&
                        !unlockProtectedVisible.includes(parentMasterID))
                  ]
                }
              />
            )}
            {checkIfUseThisElement("richTextEditorNeeded") && (
              <Editor
                theme="snow"
                id={
                  parentMasterID +
                  "-" +
                  parentsParentKey +
                  "-" +
                  parentKey +
                  "-" +
                  key +
                  "textarea"
                }
                key={parentKey + "-" + key}
                name={parentKey + "-" + key}
                data-category={catalogItemsObj[key]}
                placeholder={key}
                title={key}
                data-section={section}
                data-parentkey={parentKey}
                data-parentsparentkey={parentsParentKey ? parentsParentKey : ""}
                data-parentmasterid={parentMasterID}
                defaultValue={catalogItemsObj[key]}
                onChange={(...params) => {
                  const content = params[0];
                  const source = params[2];
                  if (source !== "user") return null;

                  addInputData({
                    target: {
                      type: "textarea",
                      value: content.trim(),
                      getAttribute: (attribute) => {
                        let output = "";
                        switch (attribute) {
                          case "data-parentmasterid":
                            output = parentMasterID;
                            break;
                          case "data-parentkey":
                            output = parentKey;
                            break;
                          case "data-section":
                            output = section;
                            break;
                          case "title":
                            output = key;
                            break;
                        }
                        return output;
                      },
                    },
                    preventDefault: () => {},
                  });
                }}
                className={
                  styles[
                    "protectedVisible-" +
                      (displayConditions.protectedVisible.includes(
                        "PROTECT-ALL"
                      ) && !unlockProtectedVisible.includes(parentMasterID)) ||
                      (displayConditions &&
                        Object.hasOwn(displayConditions, "protectedVisible") &&
                        displayConditions.protectedVisible.includes(key) &&
                        !unlockProtectedVisible.includes(parentMasterID))
                  ]
                }
              />
            )}
            {urlNotValid && (
              <ul className={styles["invalid-url-text"]}>
                <p>This is not a valid URL.</p>
                <li>
                  it must begin with
                  <code>https://</code>, <code>http://</code> or{" "}
                  <code>ftp://</code>
                </li>
                <li>
                  It must include a domain extension like <code>.com</code>,{" "}
                  <code>.net</code>, <code>.rock</code> or any similar domain
                  extension.
                </li>
                <li>
                  A <code>?</code> must be used after the domain extension for
                  additional parameters. Ex:{" "}
                  <code>https://text.com?param1=true</code>
                </li>
                <li>No whitespace in between letters.</li>
              </ul>
            )}
          </Fragment>
        )}
      {!onlyList && displayConditions && checkIfUseThisElement("isURL") && (
        <Fragment>
          <label
            id={
              parentMasterID +
              "-" +
              parentsParentKey +
              "-" +
              parentKey +
              "-" +
              key +
              "label"
            }
            htmlFor={parentKey + "-" + key}
            data-section={section}
            className={
              styles[
                "protectedHidden-" +
                  displayConditions.protectedHidden.includes(key)
              ] +
              " " +
              styles[
                "protectedVisible-" +
                  (displayConditions.protectedVisible.includes("PROTECT-ALL") &&
                    !unlockProtectedVisible.includes(parentMasterID)) ||
                  (displayConditions.protectedVisible.includes(key) &&
                    !unlockProtectedVisible.includes(parentMasterID))
              ]
            }
          >
            {key}:
          </label>

          <input
            type="url"
            id={
              parentMasterID +
              "-" +
              parentsParentKey +
              "-" +
              parentKey +
              "-" +
              key +
              "url"
            }
            key={parentKey + "-" + key}
            name={parentKey + "-" + key}
            defaultValue={catalogItemsObj[key]}
            data-category={key}
            placeholder={key}
            title={key}
            data-section={section}
            data-parentkey={parentKey}
            data-parentsparentkey={
              parentsParentKey ? parentsParentKey.toString() : ""
            }
            data-parentmasterid={parentMasterID}
            onChange={addInputData}
            className={
              styles[
                "protectedHidden-" +
                  displayConditions.protectedHidden.includes(key)
              ] +
              " " +
              styles[
                "protectedVisible-" +
                  (displayConditions.protectedVisible.includes("PROTECT-ALL") &&
                    !unlockProtectedVisible.includes(parentMasterID)) ||
                  (displayConditions.protectedVisible.includes(key) &&
                    !unlockProtectedVisible.includes(parentMasterID))
              ]
            }
          />
          {urlNotValid && (
            <ul className={styles["invalid-url-text"]}>
              <p>This is not a valid URL.</p>
              <li>
                it must begin with
                <code>https://</code>, <code>http://</code> or{" "}
                <code>ftp://</code>
              </li>
              <li>
                It must include a domain extension like <code>.com</code>,{" "}
                <code>.net</code>, <code>.rock</code> or any similar domain
                extension.
              </li>
              <li>
                A <code>?</code> must be used after the domain extension for
                additional parameters. Ex:{" "}
                <code>https://text.com?param1=true</code>
              </li>
              <li>No whitespace in between letters.</li>
            </ul>
          )}
        </Fragment>
      )}
      {!onlyList &&
        checkIfUseThisElement("isBoolean") &&
        displayConditions.isBoolean.includes(key) && (
          <Fragment>
            <label
              id={
                parentMasterID +
                "-" +
                parentsParentKey +
                "-" +
                parentKey +
                "-" +
                key +
                "label"
              }
              htmlFor={parentKey + "-" + key}
              data-section={section}
              className={
                styles[
                  "protectedHidden-" +
                    displayConditions.protectedHidden.includes(key)
                ] +
                " " +
                styles[
                  "protectedVisible-" +
                    (displayConditions.protectedVisible.includes(
                      "PROTECT-ALL"
                    ) && !unlockProtectedVisible.includes(parentMasterID)) ||
                    (displayConditions.protectedVisible.includes(key) &&
                      !unlockProtectedVisible.includes(parentMasterID))
                ]
              }
            >
              {key === "active" &&
                catalogItemsObj[key] &&
                catalogItemsObj[key] === "false" && <span>Not </span>}
              {key}:
            </label>
            <select
              id={
                parentMasterID +
                "-" +
                parentsParentKey +
                "-" +
                parentKey +
                "-" +
                key +
                "select"
              }
              key={parentKey + "-" + key}
              name={parentKey + "-" + key}
              defaultValue={catalogItemsObj[key]}
              data-category={key}
              placeholder={key}
              title={key}
              data-section={section}
              data-parentkey={parentKey}
              data-parentsparentkey={
                parentsParentKey ? parentsParentKey.toString() : ""
              }
              data-parentmasterid={parentMasterID}
              onChange={addInputData}
              className={
                styles[
                  "protectedHidden-" +
                    displayConditions.protectedHidden.includes(key)
                ] +
                " " +
                styles[
                  "protectedVisible-" +
                    (displayConditions.protectedVisible.includes(
                      "PROTECT-ALL"
                    ) && !unlockProtectedVisible.includes(parentMasterID)) ||
                    (displayConditions.protectedVisible.includes(key) &&
                      !unlockProtectedVisible.includes(parentMasterID))
                ]
              }
            >
              {" "}
              <option value={false}>False</option>
              <option value={true}>True</option>
            </select>
          </Fragment>
        )}
      {!onlyList && checkIfUseThisElement("isDate") && (
        <Fragment>
          <label
            id={
              parentMasterID +
              "-" +
              parentsParentKey +
              "-" +
              parentKey +
              "-" +
              key +
              "label"
            }
            htmlFor={parentKey + "-" + key}
            data-section={section}
            className={
              styles[
                "protectedHidden-" +
                  displayConditions.protectedHidden.includes(key)
              ] +
              " " +
              styles[
                "protectedVisible-" +
                  (displayConditions.protectedVisible.includes("PROTECT-ALL") &&
                    !unlockProtectedVisible.includes(parentMasterID)) ||
                  (displayConditions.protectedVisible.includes(key) &&
                    !unlockProtectedVisible.includes(parentMasterID))
              ]
            }
          >
            {key}:
          </label>
          <input
            id={
              parentMasterID +
              "-" +
              parentsParentKey +
              "-" +
              parentKey +
              "-" +
              key +
              "input"
            }
            key={parentKey + "-" + key}
            name={parentKey + "-" + key}
            type="datetime-local"
            placeholder={catalogItemsObj[key]}
            title={key}
            data-section={section}
            data-parentkey={parentKey}
            data-parentsparentkey={
              parentsParentKey ? parentsParentKey.toString() : ""
            }
            data-parentmasterid={parentMasterID}
            onChange={addInputData}
            defaultValue={
              catalogItemsObj[key]
                ? catalogItemsObj[key] &&
                  new Date(
                    new Date(catalogItemsObj[key]).getTime() -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .slice(0, 19)
                : new Date()
            }
            className={
              styles[
                "protectedHidden-" +
                  displayConditions.protectedHidden.includes(key)
              ] +
              " " +
              styles[
                "protectedVisible-" +
                  (displayConditions.protectedVisible.includes("PROTECT-ALL") &&
                    !unlockProtectedVisible.includes(parentMasterID)) ||
                  (displayConditions.protectedVisible.includes(key) &&
                    !unlockProtectedVisible.includes(parentMasterID))
              ]
            }
          />
        </Fragment>
      )}
      {onlyList && <span>{catalogItemsObj[key]}</span>}
    </li>
  );

  return output;
};

export default CatalogItem;
