import { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import styles from "./CatalogItems.module.css";
import CatalogItemsList from "./CatalogItemsList/CatalogItemsList";
import displayConditions from "../../data/displayConditionsObj.js";
import useCreateNewForm from "../../Hooks/useCreateNewForm";

import { toTitleCase } from "../../Hooks/utility";

import CollapsibleElm from "../../UI/CollapsibleElm/CollapsibleElm";

const CatalogItems = (props) => {
  const user = useSelector((state) => state.auth.user);
  const { catalogMetadata, catalogModel } = useSelector(
    (state) => state.catalogData
  );
  const { contentModel } = useSelector((state) => state.contentData);
  const id = props.id;
  const typeName = props.type;
  const dataObjForEdit = props.dataObjForEdit;
  const [formInputData, setFormInputData] = useState({});
  const [newFormJSX, setNewFormJSX] = useState(false);
  const createNewForm = useCreateNewForm();
  const [newFormInputValuesObj, setNewFormInputValuesObj] = useState({});
  const newFormInputValuesObjRef = useRef();
  newFormInputValuesObjRef.current = newFormInputValuesObj;

  ////////////////////////////////////////////////////////////////////////
  /// EFFECTS
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (dataObjForEdit && catalogModel && contentModel) {
      let model = catalogModel;
      if (id === "content") {
        model = contentModel;
      }

      const output = {};
      // Gather services available for sourceURLObj
      const availableServices =
        catalogMetadata && Object.hasOwn(catalogMetadata, "availableServices")
          ? [...catalogMetadata.availableServices]
          : [""];
      const inactiveServices =
        catalogMetadata && catalogMetadata.hasOwnProperty("inactiveServices")
          ? [...catalogMetadata.inactiveServices]
          : [];
      const allServices = [...availableServices, ...inactiveServices];
      for (const itemID in dataObjForEdit) {
        output[itemID] = {};
        for (const catName of Object.keys(model)) {
          // If sourceURLObj, add services
          if (catName === "sourceURLObj") {
            output[itemID][catName] = {};
            allServices.forEach((serviceName) => {
              if (serviceName === "") return;

              if (
                Object.hasOwn(dataObjForEdit[itemID], catName) &&
                Object.hasOwn(dataObjForEdit[itemID][catName], serviceName) &&
                dataObjForEdit[itemID][catName][serviceName]
              ) {
                output[itemID][catName][serviceName] =
                  dataObjForEdit[itemID][catName][serviceName];
              } else {
                output[itemID][catName][serviceName] = "";
              }
            });
          } else {
            output[itemID][catName] = Object(dataObjForEdit[itemID], catName)
              ? dataObjForEdit[itemID][catName]
              : "";
          }
        }
      }
      setFormInputData(output);
    } else {
      setFormInputData((prevState) => {
        const existingState = { ...prevState };
        existingState.catalog = catalogModel;
        return existingState;
      });
    }
  }, [catalogModel, contentModel]);

  ////////////////////////////////////////////////////////////////////////
  /// HANDLERS
  ////////////////////////////////////////////////////////////////////////
  const addFormButtonHandler = (e) => {
    e.preventDefault();
    createNewForm({
      e,
      styles,
      setNewFormJSX,
      id,
      user,
      setNewFormInputValuesObj,
      currentNewFormInputValuesObjRef: newFormInputValuesObjRef.current,
    });
  };

  const outputName =
    dataObjForEdit[id] && Object.hasOwn(dataObjForEdit[id], "title") ? (
      <Fragment>
        <div>{dataObjForEdit[id].title}</div>
        <div>{id}</div>
      </Fragment>
    ) : typeName ? (
      typeName + "s"
    ) : (
      id
    );
  ////////////////////////////////////////////////////////////////////////
  /// Output
  ////////////////////////////////////////////////////////////////////////
  // Output only "Add" button if requested.
  if (props.onlyAddButton)
    return (
      <div>
        {newFormJSX && (
          <div id="new-form-modal" className={styles["new-form-modal"]}>
            <form>{newFormJSX}</form>
          </div>
        )}
        <button
          className={styles["new-form-button"]}
          value={id}
          data-parentmasterid={id}
          onClick={addFormButtonHandler}
        >
          Add to <span>{id}</span>
        </button>
      </div>
    );

  return (
    <ul
      data-marker="CATALOG-ITEMS"
      data-section={id}
      id={id}
      type={typeName}
      className={
        styles["catalog-items-group"] +
        " " +
        styles["group-" + id] +
        " " +
        styles[id]
      }
    >
      <div className={styles["section-title-stats-container"]}>
        {" "}
        <h2 className={styles["group-title"] + " " + styles[id]}>
          {outputName}
        </h2>
        {formInputData && (
          <div className={styles["section-stats-container"]}>
            <ul>
              <li
                className={
                  Object.values(formInputData).filter((row) => {
                    return (
                      row &&
                      Object.hasOwn(row, "active") &&
                      row.active &&
                      row.active != "false"
                    );
                  }).length <= 0 && styles["no-active-items-warning"]
                }
              >
                <span>Active: </span>
                <span>
                  {" "}
                  {
                    Object.values(formInputData).filter(
                      (row) =>
                        row &&
                        Object.hasOwn(row, "active") &&
                        row.active &&
                        row.active != "false"
                    ).length
                  }
                </span>
              </li>
              <li>
                <span>Total: </span>
                <span> {Object.keys(formInputData).length}</span>
              </li>
            </ul>
          </div>
        )}
      </div>
      <CollapsibleElm
        id={id + "-collapsible-elm"}
        styles={{
          position: "relative",
        }}
        maxHeight={"0em"}
        s
        inputOrButton="button"
        buttonStyles={{
          margin: "0 auto",
          padding: "0.5em 2em",
          letterSpacing: "0.25em",
          fontVariant: "small-caps",
          transform: "translateY(0%)",
          transition: "0.7s all ease",
          minWidth: "80%",
          maxWidth: "80%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",

          borderRadius: "50px",
          fontFamily: "Arial",

          boxShadow: "none",
          border: "3px solid var(--ms1-color-accent-dark)",
        }}
        colorType="primary"
        data=""
        size="small"
        buttonTextOpened={"- Close All " + toTitleCase(outputName) + " -"}
        buttonTextClosed={"- Open All " + toTitleCase(outputName) + " -"}
        open={false}
      >
        {formInputData && Object.keys(formInputData).length > 0 && (
          <CatalogItemsList
            catalogItemsObj={formInputData}
            section={id}
            parentKey={false}
            parentsParentKey={false}
            parentmasterid={false}
            displayConditions={displayConditions.formWithPreFilledData}
            user={user}
          />
        )}
        {newFormJSX && (
          <div id="new-form-modal" className={styles["new-form-modal"]}>
            <form>{newFormJSX}</form>
          </div>
        )}
      </CollapsibleElm>
      <button
        className={styles["new-form-button"]}
        value={id}
        data-parentmasterid={id}
        onClick={addFormButtonHandler}
      >
        Add to <span>{id}</span>
      </button>
    </ul>
  );
};

export default CatalogItems;
