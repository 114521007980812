import { useState, useEffect, useLayoutEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Manage.module.css";
import CardTransparent from "../../UI/Cards/CardTransparent/CardTransparent";
import CardPrimary from "../../UI/Cards/CardPrimary/CardPrimary";
import CardSecondary from "../../UI/Cards/CardSecondary/CardSecondary";
import Footer from "../../Components/Footer/Footer";
import LoginStatus from "../../Components/User/LoginStatus/LoginStatus";
import { ErrorBoundary } from "../../HOC/ErrorHandling/ErrorBoundary/ErrorBoundary";
import { scrollPositionActions } from "../../store/scrollPositionSlice";
import CatalogItems from "../../Components/CatalogItems/CatalogItems";
import CatalogItemsList from "../../Components/CatalogItems/CatalogItemsList/CatalogItemsList";
import CollapsibleElm from "../../UI/CollapsibleElm/CollapsibleElm";
import { toTitleCase } from "../../Hooks/utility";
import BarLoader from "../../UI/Loaders/BarLoader/BarLoader";
import { formInputDataActions as allFormInputDataActions } from "../../store/formInputDataSlice";
import { sha256 } from "js-sha256";
import { saveManyCatalogItems } from "../../storage/catalogDB";
import { saveManyContentItems } from "../../storage/contentDB";

const Manage = (props) => {
  const user = useSelector((state) => state.auth.user);
  const loadingStatus = useSelector(
    (state) => state.loadingRequests.pendingLoadRequests
  );
  const allFormInputData = useSelector((state) => state.formInputData);
  const { catalogModel } = useSelector((state) => state.catalogData);
  const { contentModel } = useSelector((state) => state.contentData);
  const contentData = useSelector((state) => state.contentData);
  const catalogData = useSelector((state) => state.catalogData);
  const [outputJSX, setOutputJSX] = useState(false);
  const [contentOutputJSX, setContentOutputJSX] = useState(false);
  const angledRectangleRef = useRef();
  const dispatch = useDispatch();
  const dataObj = catalogData;
  const jsxOutputInstructions = {
    itemsToList: ["catalogMetadata", "contentMetadata"],
    itemsToEdit: ["catalog", "services", "content"],
  };

  ////////////////////////////////////////////////////////////////////////
  /// Effects
  ////////////////////////////////////////////////////////////////////////
  useLayoutEffect(() => {
    const updateScrollPosition = () => {
      if (!angledRectangleRef.current) return;

      const welcomeScrollPosition =
        angledRectangleRef.current.getBoundingClientRect();

      dispatch(
        scrollPositionActions.updateWelcomeScrollPosition(
          JSON.parse(JSON.stringify(welcomeScrollPosition))
        )
      );
    };
    window.addEventListener("scroll", updateScrollPosition);
    updateScrollPosition();
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  useEffect(() => {
    const dataObjForEdit = {};
    const dataObjForList = {};

    for (const key in dataObj) {
      if (jsxOutputInstructions.itemsToEdit.includes(key))
        dataObjForEdit[key] = dataObj[key];
      if (jsxOutputInstructions.itemsToList.includes(key))
        dataObjForList[key] = dataObj[key];
    }

    const outputObj = [];

    if (dataObjForEdit.catalog)
      if (dataObjForEdit) {
        for (const key in dataObjForEdit) {
          if (key && dataObjForEdit[key]) {
            const sortedDataObject = {};
            for (const i in dataObjForEdit[key]) {
              const type = dataObjForEdit[key][i].type;
              if (!sortedDataObject.hasOwnProperty(type))
                sortedDataObject[type] = {};
              sortedDataObject[type][i] = dataObjForEdit[key][i];
            }

            outputObj.push(
              <h1 key={key + "title"} className={styles["major-group-title"]}>
                The {key}
              </h1>
            );

            // Display add button if database is empty.

            outputObj.push(
              <div className={styles["add-to-button-wrap"]}>
                <CatalogItems
                  key={"no-db-items-list"}
                  id={"catalog"}
                  dataObjForEdit={false}
                  user={user}
                  type={"no-db-items-list"}
                  onlyAddButton={true}
                />
              </div>
            );

            for (const typeName in sortedDataObject) {
              outputObj.push(
                <div
                  key={key + typeName}
                  className={styles["section"] + " " + styles[typeName]}
                >
                  <CatalogItems
                    key={key + "catItems"}
                    id={key}
                    dataObjForEdit={sortedDataObject[typeName]}
                    user={user}
                    type={typeName}
                  />
                </div>
              );
            }
          }
        }
      }
    if (dataObjForList) {
      for (const key in dataObjForList) {
        if (key && dataObjForList[key]) {
          outputObj.push(
            <Fragment key={key + "frag"}>
              <div
                key={key + "parentDIv"}
                className={styles.section + " " + styles["section-" + key]}
              >
                <h3
                  key={key + "h3Title"}
                  className={
                    styles["section-subtitle"] +
                    " " +
                    styles.section +
                    " " +
                    styles["section-" + key]
                  }
                >
                  {key}
                </h3>

                <CollapsibleElm
                  key={key + "-collapsible-elm"}
                  id={key + "-collapsible-elm"}
                  styles={{
                    position: "relative",
                  }}
                  maxHeight={"0em"}
                  s
                  inputOrButton="button"
                  buttonStyles={{
                    margin: "0 auto",
                    padding: "0.5em 2em",
                    letterSpacing: "0.25em",
                    fontVariant: "small-caps",
                    transform: "translateY(0%)",
                    transition: "0.7s all ease",
                    minWidth: "80%",
                    maxWidth: "80%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "50px",
                    fontFamily: "Arial",
                    boxShadow: "none",
                    border: "3px solid var(--ms1-color-accent-dark)",
                  }}
                  colorType="primary"
                  data=""
                  size="small"
                  buttonTextOpened={"- Close " + toTitleCase(key) + " -"}
                  buttonTextClosed={"- Open " + toTitleCase(key) + " -"}
                  open={false}
                >
                  <CatalogItemsList
                    key={key + "catItemList"}
                    catalogItemsObj={dataObjForList[key]}
                    parentKey={false}
                    parentsParentKey={false}
                    parentMasterID={false}
                    displayConditions={false}
                    onlyList={true}
                  />
                </CollapsibleElm>
              </div>
            </Fragment>
          );
        }
      }
    }

    setOutputJSX(outputObj);
  }, [dataObj]);

  useEffect(() => {
    const dataObjForEdit = {};
    const dataObjForList = {};

    for (const key in contentData) {
      if (jsxOutputInstructions.itemsToEdit.includes(key))
        dataObjForEdit[key] = contentData[key];
      if (jsxOutputInstructions.itemsToList.includes(key))
        dataObjForList[key] = contentData[key];
    }

    const outputObj = [];
    if (dataObjForEdit) {
      for (const key in dataObjForEdit) {
        if (key && dataObjForEdit[key]) {
          const sortedDataObject = {};
          for (const i in dataObjForEdit[key]) {
            const type = dataObjForEdit[key][i].type;
            if (!sortedDataObject.hasOwnProperty(type))
              sortedDataObject[type] = {};
            sortedDataObject[type][i] = dataObjForEdit[key][i];
          }

          outputObj.push(
            <h1 key={key + "h1"} className={styles["major-group-title"]}>
              The {key}
            </h1>
          );

          // Display add button if database is empty.

          outputObj.push(
            <div className={styles["add-to-button-wrap"]}>
              <CatalogItems
                key={"no-db-items-list"}
                id={"content"}
                dataObjForEdit={false}
                user={user}
                type={"no-db-items-list"}
                onlyAddButton={true}
              />
            </div>
          );

          for (const typeName in sortedDataObject) {
            outputObj.push(
              <div
                key={key + typeName + "div"}
                className={styles["section"] + " " + styles[typeName]}
              >
                <CatalogItems
                  key={key + typeName + "list"}
                  id={key}
                  dataObjForEdit={sortedDataObject[typeName]}
                  user={user}
                  type={typeName}
                />
              </div>
            );
          }
        }
      }
    }

    if (dataObjForList) {
      for (const key in dataObjForList) {
        if (key && dataObjForList[key]) {
          outputObj.push(
            <Fragment key={key + "frag2"}>
              <div
                key={key + "parent"}
                className={styles.section + " " + styles["section-" + key]}
              >
                <h3
                  key={key + "h3"}
                  className={
                    styles["section-subtitle"] +
                    " " +
                    styles.section +
                    " " +
                    styles["section-" + key]
                  }
                >
                  {key}
                </h3>
                <CollapsibleElm
                  key={key + "collapseElm"}
                  id={key + "-collapsible-elm"}
                  styles={{
                    position: "relative",
                  }}
                  maxHeight={"0em"}
                  s
                  inputOrButton="button"
                  buttonStyles={{
                    margin: "0 auto",
                    padding: "0.5em 2em",
                    letterSpacing: "0.25em",
                    fontVariant: "small-caps",
                    transform: "translateY(0%)",
                    transition: "0.7s all ease",
                    minWidth: "80%",
                    maxWidth: "80%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "50px",
                    fontFamily: "Arial",
                    boxShadow: "none",
                    border: "3px solid var(--ms1-color-accent-dark)",
                  }}
                  colorType="primary"
                  data=""
                  size="small"
                  buttonTextOpened={"- Close " + toTitleCase(key) + " -"}
                  buttonTextClosed={"- Open " + toTitleCase(key) + " -"}
                  open={false}
                >
                  <CatalogItemsList
                    key={key + "catList"}
                    catalogItemsObj={dataObjForList[key]}
                    parentKey={false}
                    parentsParentKey={false}
                    parentMasterID={false}
                    displayConditions={false}
                    onlyList={true}
                  />
                </CollapsibleElm>
              </div>
            </Fragment>
          );
        }
      }
    }

    setContentOutputJSX(outputObj);
  }, [contentData]);

  useEffect(() => {
    if (allFormInputData.allNewForms && user) {
      let model = catalogModel;
      let saveManyItems = saveManyCatalogItems;
      const categoryName = Object.keys(allFormInputData.allNewForms);

      if (categoryName && categoryName[0] === "content") {
        model = contentModel;
        saveManyItems = saveManyContentItems;
      }

      const outputDataArray = [];
      const requiredFields = [];
      const groomedBlankForm = {};

      /////// Functions for later use ///////
      const lookForMissingRequirements = (measureArray, testObj) => {
        const filteredTestObj = {};
        for (const [key, value] of Object.entries(testObj)) {
          const groomedValue =
            value.constructor === String ? value.replace(/\s/g, "") : value;
          if (groomedValue !== "") filteredTestObj[key] = groomedValue;
        }
        const missingRequiredFields = measureArray.filter(
          (requiredFiledName) =>
            !Object.keys(filteredTestObj).includes(requiredFiledName)
        );
        return missingRequiredFields;
      };

      const flattenNestedObjToArr = (nestedObj) => {
        const outputArray = [];
        // new-form
        for (const l1Key in nestedObj) {
          // Catalog
          for (const l2Key in nestedObj[l1Key]) {
            // The base form
            const newObject = {};
            for (const l3Key in nestedObj[l1Key][l2Key]) {
              newObject[l3Key] = nestedObj[l1Key][l2Key][l3Key];
            }
            outputArray.push(newObject);
          }
        }
        return outputArray;
      };

      /////// Begin processing  ///////
      // Prepare blank form and required fields
      for (const schemaKey in model) {
        if (schemaKey === "_id") continue;
        if (
          model[schemaKey].hasOwnProperty("required") &&
          model[schemaKey].required
        )
          requiredFields.push(schemaKey);

        groomedBlankForm[schemaKey] = "";
      }

      const flatInputDataArray = flattenNestedObjToArr(
        allFormInputData.allNewForms,
        2
      );

      // Check requirements
      const missingRequiredFields = [];
      flatInputDataArray.forEach((form) => {
        missingRequiredFields.push(
          lookForMissingRequirements(requiredFields, form)
        );
      });

      const cleanMissingFieldsArray = new Set();
      missingRequiredFields.forEach((arr) => {
        if (arr) arr.forEach((value) => cleanMissingFieldsArray.add(value));
      });

      if (cleanMissingFieldsArray.size > 0) {
        const errorMessage =
          "Unfortunately, there is missing required data preventing this from submitting. Please fill out the " +
          [...cleanMissingFieldsArray]
            .toString()
            .replace(/,(?=[^,]+$)/, ", and ") +
          " fields on each form.";
        alert(errorMessage);
        return;
      }

      // Requirements met and ready to prepare data upload.
      const newFormData = flatInputDataArray.map((formData) => {
        const newForm = { ...groomedBlankForm };
        Object.keys(newForm).forEach((key) => {
          let value = formData[key] ? formData[key] : newForm[key];

          // Handle special identifier.
          const date = new Date();
          let year = date.getFullYear();
          if (key === "identifier") {
            const hashID = sha256(JSON.stringify(formData["title"]));
            value = year + "-" + hashID;
          }

          // Special dates.
          if (key === "createdAt" || key === "updatedAt") {
            value = date;
          }

          newForm[key] = value;
        });
        return newForm;
      });

      outputDataArray.push(...newFormData);

      saveManyItems({ user, outputDataArray }).then(() => {
        // console.log("Success! An item has been saved to the catalog.");
        dispatch(allFormInputDataActions.resetSubmitAllNewForms());
        const confirm = window.confirm(
          "Success! Additions were saved to the database. Would you like to refresh?"
        );
        if (confirm) window.location.reload(false);
      });
    }
  }, [allFormInputData.allNewForms]);
  ////////////////////////////////////////////////////////////////////////
  /// Functionality
  ////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////
  /// Handlers
  ////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////
  /// Output
  ////////////////////////////////////////////////////////////////////////
  /* eslint eqeqeq: 0 */
  return (
    <div className={styles["page-wrap"]}>
      {loadingStatus && (
        <div className="bar-loader-container">
          <div className="bar-loader-wrap">
            <BarLoader />
          </div>
        </div>
      )}
      <div className={styles["welcome-section-container"]}>
        <div className={styles["angled-rectangle"]} ref={angledRectangleRef}>
          <div className={styles["background-video-wrap"]}>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
          </div>
        </div>
        <Fragment>
          <CardTransparent styles={{ overflow: "hidden" }}>
            <ErrorBoundary>
              <div className={styles["main-music-player"]}>
                <div className={styles["main-music-player-wrap"]}>
                  <LoginStatus user={props.user} />
                </div>
              </div>
            </ErrorBoundary>
          </CardTransparent>
        </Fragment>
      </div>
      <CardSecondary>
        {" "}
        <CardPrimary>
          {" "}
          <div className={styles["title-wrap"]}>
            <h1
              key="qwewertyuifgh"
              className={styles["ms1-title"] + " " + styles["section-title"]}
            >
              Manage{" "}
            </h1>
            <h2
              key="qwewertyuifghjdvdfvd"
              className={styles["ms1-title"] + " " + styles["first-word"]}
            >
              Ignite{" "}
              <span
                className={styles["ms1-title"] + " " + styles["second-word"]}
              >
                Elysium
              </span>
            </h2>
            <h3 className={styles["ms1-subtitle"]}>United in Rhythm</h3>
          </div>{" "}
          {!user && (
            <h2 style={{ fontFamily: "Arial, sans-serif" }}>
              Alert: You need to be logged in with administrator privileges to
              be able to interact with the Management content. Please contact
              the site admin if you believe you should be an administrator and
              need to have an account set up.
            </h2>
          )}
          {user && user.isAdmin != true && (
            <h2 style={{ fontFamily: "Arial, sans-serif" }}>
              Alert: {user.userName} is logged in, but this user is not an
              administrator. Please contact the site admin if you believe this
              to be an error.
            </h2>
          )}
        </CardPrimary>
      </CardSecondary>
      {user && user.isAdmin == true && (
        <Fragment>
          <CardPrimary>
            <ErrorBoundary>
              <form>
                <ul
                  key="asdgsdsdgsdfgsdfg"
                  className={
                    styles["master-list-container"] +
                    " " +
                    styles["master-catalog"]
                  }
                >
                  {outputJSX}
                </ul>
              </form>
            </ErrorBoundary>
          </CardPrimary>
          <CardPrimary>
            <ErrorBoundary>
              <form>
                <ul
                  key="hkgyuytuktyukuy"
                  className={
                    styles["master-list-container"] +
                    " " +
                    styles["master-content"]
                  }
                >
                  {contentOutputJSX}
                </ul>
              </form>
            </ErrorBoundary>
          </CardPrimary>
        </Fragment>
      )}
      <CardSecondary styles={{ margin: "0" }}>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </CardSecondary>
    </div>
  );
};

export default Manage;
