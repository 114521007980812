import styles from "./ServiceSelector.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { musicPlayerActions } from "../../../store/musicPlayerSlice";

const ServiceSelector = (props) => {
  const { serviceSelected } = useSelector((state) => state.musicPlayer);
  const { catalog, catalogMetadata } = useSelector(
    (state) => state.catalogData
  );
  const { availableServices, availableServicesNamesObj, isDefaultService } =
    catalogMetadata;
  let defaultService =
    catalog && isDefaultService && catalog[isDefaultService[1]].slug;
  if (
    !defaultService ||
    !catalogMetadata.availableServices.includes(catalogMetadata)
  )
    defaultService = catalogMetadata.availableServices[0];
  const [activeTab, setActiveTab] = useState(
    serviceSelected ? serviceSelected : defaultService
  );

  useEffect(() => {
    dispatch(musicPlayerActions.updateServiceSelected(defaultService));
  }, []);

  useEffect(() => {
    setActiveTab(serviceSelected ? serviceSelected : defaultService);
  }, [serviceSelected]);

  const dispatch = useDispatch();

  const serviceSelectionButtonHandler = (e) => {
    dispatch(musicPlayerActions.updateServiceSelected(e.target.value));
  };

  return (
    <div className={styles["tab-container"]} style={props.styles}>
      {props.title && (
        <div className={styles["player-title-wrap"]}>
          <h2 className={styles["player-title"]}>{props.title}</h2>
        </div>
      )}
      <div className={styles["tab-container-inner-wrap"]}>
        {availableServices.length === 1 && (
          <div className={styles["tab-name"] + " " + styles["single-tab"]}>
            <h3>{availableServices[0]}</h3>
          </div>
        )}
        {availableServices.length > 1 &&
          availableServices.map((service) => (
            <button
              key={service}
              value={service}
              onClick={serviceSelectionButtonHandler}
              className={
                styles["tab-name"] +
                " " +
                styles["active-" + (activeTab === service)]
              }
            >
              <h3>{availableServicesNamesObj[service]}</h3>
            </button>
          ))}
      </div>
    </div>
  );
};

export default ServiceSelector;
