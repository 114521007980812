import reactStringReplace from "react-string-replace";
import SubscribeCTA from "../Components/SubscribeCTA/SubscribeCTA";

const useShortCodes = () => {
  const outputFunction = (props) => {
    // Separate target strings  with a |. Be sure to consider escaped characters.
    const regexMatchstring = /(<SUBSCRIBE>|&lt;SUBSCRIBE&gt;)/g;
    let inputText = props.text ? props.text : "";

    return reactStringReplace(inputText, regexMatchstring, () => (
      <div key={"subscribe-cta"} className={props.styles["subscribe-cta-wrap"]}>
        <SubscribeCTA />{" "}
      </div>
    ));
  };

  return outputFunction;
};

export default useShortCodes;

// Saved for future short code use
// const parts = inputText.split(" ");
// const newTextParts = "<SUBSCRIBE>";

// for (const value of parts) {
//   const newValue = value.split("|");

// if (value.includes("<LINK=")) {
//   // Build Link
//   const url = newValue
//     .filter((text) => text.includes("LINK"))[0]
//     .replace("<LINK=", "")
//     .replaceAll('"', "")
//     .trim();
//   const text = newValue
//     .filter((text) => text.includes("TEXT"))[0]
//     .replace("TEXT=", "")
//     .replaceAll('"', "")
//     .trim();
//   newTextParts.push(
//     <div className={props.styles["subscribe-cta-wrap"]}>
//       <a href={url}>{text}</a>
//     </div>
//   );
// }
