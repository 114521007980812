import { createSlice } from "@reduxjs/toolkit";

function InitState() {
  const initialState = {};
  initialState.catalog = null;
  initialState.catalogMetadata = null;
  initialState.catalogModel = null;
  initialState.serviceEmbedJSXObj = null;
  return initialState;
}

export const catalogDataSlice = createSlice({
  name: "catalogData",
  initialState: InitState(),
  reducers: {
    initState: (state, action) => {
      const payload = action.payload;

      state.catalog = payload.catalog;
      state.catalogMetadata = payload.catalogMetadata;
      state.catalogModel = payload.catalogModel;
      state.serviceEmbedJSXObj = payload.serviceEmbedJSXObj;
    },

  
  },
});

// Action creators are generated for each case reducer function
export const catalogDataActions = catalogDataSlice.actions;

export default catalogDataSlice.reducer;
