import styles from "./SubscribeCTA.module.css";
import { useState, Fragment } from "react";
import { sha256 } from "js-sha256";

const SubscribeCTA = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const subscribeEmailAddress = "contact@igniteelysium.com";
  const emailInput = (e) => {
    setEmailAddress(e.target.value);
  };

  const modalOKClickHandler = () => {
    const cleanEmailAddress = emailAddress.replaceAll(" ", "");
    const isValidEmailAddress = cleanEmailAddress
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!isValidEmailAddress) {
      alert(
        `Unfortunately, "${cleanEmailAddress}" does not appear to be a valid email address. Please fix the email address and try to submit again.`
      );
    } else {
      const subject = "Let's Keep In Touch | Ignite Elysium";
      const dataObj = { email: emailAddress, time: Date.now() };
      const hashID = sha256(JSON.stringify(dataObj));
      const body = `Hello.\n\nPlease let ${emailAddress} know of Ignite Elysium updates and when new music is about to drop.\n\nReferenceID: ${hashID}`;

      window.open(
        `mailto:${subscribeEmailAddress}?subject=${subject}l&body=${encodeURIComponent(
          body
        )}`
      );
      setConfirmModal("AFTER SUBMIT");
    }
  };
  const modalNOClickHandler = () => {
    setConfirmModal(false);
  };
  const openEmailEditorMessage = (
    <Fragment>
      <div className={styles.background}></div>
      <div className={styles.content}>
        <h3 class="sub-title">Thanks for keeping in touch. </h3>
        <p>To prevent spam, we use a simple two-step process.</p>
        <ol>
          <li>Click "OK" here to open your email client.</li>
          <li>
            A ready-to-send email will already be there and you only need click
            to send it.
          </li>
        </ol>{" "}
        <p>
          Or, if you prefer to send the request directly, just send a quick
          request to:
          <br />
          <span> {subscribeEmailAddress}</span> <br />
          We will take care of the rest.
        </p>
        <p>We look forward to much more together.</p>{" "}
        <div className={styles["button-container"]}>
          <button
            className={styles["button"] + " " + styles["no-button"]}
            onClick={modalNOClickHandler}
          >
            Cancel
          </button>
          <button
            className={styles["button"] + " " + styles["ok-button"]}
            onClick={modalOKClickHandler}
          >
            OK - Let's Connect!
          </button>
        </div>
      </div>
    </Fragment>
  );
  const afterSubmitMessage = (
    <Fragment>
      <div className={styles["background-2"]}></div>
      <div className={styles.content}>
        <h3 class="sub-title">Thanks!</h3>
        <p>
          At this point, an email should have been launched in your email client
          enabling you to send it. If so, hit the "Close" button below to head
          back to the site.
        </p>
        <div className={styles["button-container"]}>
          <button
            className={styles["button"] + " " + styles["ok-button"]}
            onClick={modalNOClickHandler}
          >
            Close
          </button>
        </div>
        <p>If not, click the "Try Again" button.</p>
        <div className={styles["button-container"]}>
          <button
            className={styles["button"] + " " + styles["ok-button"]}
            onClick={modalOKClickHandler}
          >
            Try Again
          </button>
        </div>
        <p>
          If it will not work for you, please send a quick email to:
          <p>{subscribeEmailAddress}</p>
          We will take care of the rest.
        </p>
      </div>
    </Fragment>
  );

  const sendEmail = (e) => {
    e.preventDefault();
    const cleanEmailAddress = emailAddress.replaceAll(" ", "");
    const isValidEmailAddress = cleanEmailAddress
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!isValidEmailAddress) {
      alert(
        `Unfortunately, "${cleanEmailAddress}" does not appear to be a valid email address. Please fix the email address and try to submit again.`
      );
    } else {
      setConfirmModal(true);
    }
  };

  return (
    <div className={styles["subscribeCTA-container"]}>
      <form className={styles["subscribeCTA-form"]} onSubmit={sendEmail}>
        <p>Stay connected</p>
        <span className={styles["subscribe-input-container"]}>
          <input
            type="text"
            className={styles["subscribeCTA-email-input"]}
            placeholder="Email address..."
            onChange={emailInput}
          />
          <input
            type="submit"
            className={styles["subscribeCTA-email-submit"]}
            value="&#11136;"
          />
        </span>
      </form>
      {confirmModal && confirmModal !== "AFTER SUBMIT" && (
        <div className={styles["confirm-modal"]}>{openEmailEditorMessage}</div>
      )}
      {confirmModal && confirmModal === "AFTER SUBMIT" && (
        <div className={styles["confirm-modal"]}>{afterSubmitMessage}</div>
      )}
    </div>
  );
};

export default SubscribeCTA;
