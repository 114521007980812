import { useLayoutEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./HowToUse.module.css";
import CardPrimary from "../../UI/Cards/CardPrimary/CardPrimary";
import CardTransparent from "../../UI/Cards/CardTransparent/CardTransparent";
import CardSecondary from "../../UI/Cards/CardSecondary/CardSecondary";
import NotFound from "../../Components/NotFound/NotFound";
import Footer from "../../Components/Footer/Footer";
import { scrollPositionActions } from "../../store/scrollPositionSlice";
import BarLoader from "../../UI/Loaders/BarLoader/BarLoader";
import { ErrorBoundary } from "../../HOC/ErrorHandling/ErrorBoundary/ErrorBoundary";

const HowToUse = (props) => {
  const angledRectangleRef = useRef();
  const dispatch = useDispatch();
  const loadingStatus = useSelector(
    (state) => state.loadingRequests.pendingLoadRequests
  );
  ////////////////////////////////////////
  /// Effects
  ////////////////////////////////////////
  useLayoutEffect(() => {
    const updateScrollPosition = () => {
      if (!angledRectangleRef.current) return;

      const welcomeScrollPosition =
        angledRectangleRef.current.getBoundingClientRect();

      dispatch(
        scrollPositionActions.updateWelcomeScrollPosition(
          JSON.parse(JSON.stringify(welcomeScrollPosition))
        )
      );
    };
    window.addEventListener("scroll", updateScrollPosition);
    updateScrollPosition();
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);
  return (
    <div className={styles["page-wrap"]}>
      <div className={styles["welcome-section-container"]}>
        <div className={styles["angled-rectangle"]} ref={angledRectangleRef}>
          <div className={styles["background-video-wrap"]}>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
            <div className={styles["bubble"]}></div>
          </div>
        </div>
        <Fragment>
          {loadingStatus > 0 && (
            <div className="bar-loader-container">
              <div className="bar-loader-wrap">
                <BarLoader />
              </div>
            </div>
          )}
          {props.notFound && (
            <CardTransparent>
              <ErrorBoundary>
                <div className={styles["not-found-wrap"]}>
                  <NotFound />
                </div>
              </ErrorBoundary>
            </CardTransparent>
          )}
        </Fragment>
        <CardSecondary>
          <br />
          <p>How To Use the Manage Section</p>
          <br />
          <br />
        </CardSecondary>
        <CardPrimary>
          <h1>How To Use the Manage Section</h1>
          <h3>Rich Text Editors</h3>
          <ul>
            <li>Only used for specific content entry points.</li>
            <ul>
              <h4>Color Formatting</h4>
              <li>
                Because we are using a special background process to create
                unique gradient text, standard color changes will not work.
                Highlights and background colors will not work, but the text
                color can be changed by using the <i>Background Color</i>{" "}
                option.
              </li>
              <li>
                The first ten, or so, colors colors in the color picker will be
                theme colors.
              </li>
            </ul>
            <li>Format: noticeOne, noticeThree, about, noticeTwo, music</li>
          </ul>
          <h3>Menu Order</h3>
          <ul>
            <li>Type to use: menuOrder</li>
            <li>Place to put the order: menuOrder text box.</li>
            <li>Format: noticeOne, noticeThree, about, noticeTwo, music</li>
          </ul>
        </CardPrimary>
      </div>

      <CardSecondary styles={{ margin: "0" }}>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </CardSecondary>
    </div>
  );
};

export default HowToUse;
