import * as DOMPurify from "dompurify";

const useDetermineAndCleanJSXOutput = () => {
  const outputFunction = (inputArray) => {
    const sanitizeTagWhitelist = [
      "<SUBSCRIBE>",
      "SUBSCRIBE",
      "iframe",
      "b",
      "q",
      "strong",
      "i",
      "br",
      "p",
      "span",
      "ol",
      "ul",
      "li",
      "u",
      "img",
      "media",
    ];
    const sanitizeAttributeWhitelist = [
      "style",
      "class",
      "target",
      "frameborder",
      "allowfullscreen",
      "autoplay",
      "src",
    ];
    return inputArray.map((row) => {
      if (typeof row === "string") {
        const cleanText = DOMPurify.sanitize(row, {
          ALLOWED_TAGS: sanitizeTagWhitelist,
          ALLOWED_ATTR: sanitizeAttributeWhitelist,
        });
        return (
          <span key={row} dangerouslySetInnerHTML={{ __html: cleanText }} />
        );
      } else {
        return row;
      }
    });
  };

  return outputFunction;
};

export default useDetermineAndCleanJSXOutput;
