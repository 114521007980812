import styles from "./NoticeOne.module.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toCamelCase } from "../../Hooks/utility";
import useDetermineAndCleanJSXOutput from "../../Hooks/useDetermineAndCleanJSXOutput";
import useShortCodes from "../../Hooks/useShortCodes";

const NoticeOne = () => {
  const { content } = useSelector((state) => state.contentData);
  const [noticeOneData, setNoticeOneData] = useState({});
  const shortCodes = useShortCodes();
  const textOne = shortCodes({ text: noticeOneData.text, styles });
  const textTwo = shortCodes({ text: noticeOneData.textTwo, styles });
  const textThree = shortCodes({ text: noticeOneData.textThree, styles });
  const textFour = shortCodes({ text: noticeOneData.textFour, styles });
  const determineAndCleanJSXOutput = useDetermineAndCleanJSXOutput();

  useEffect(() => {
    if (content) {
      Object.keys(content).forEach((key) =>
        Object.entries(content[key]).forEach((entry) => {
          const test =
            entry[0].includes("type") && entry[1].includes("noticeOne");
          if (test) setNoticeOneData(content[key]);
        })
      );
    }
  }, [content]);

  // Assemble user-supplied image css
  const imageCSSNamesArray = [
    "imageOneCSS",
    "imageTwoCSS",
    "imageThreeCSS",
    "imageFourCSS",
  ];
  const imageCSS = [];

  if (noticeOneData) {
    for (const imageCSSName of imageCSSNamesArray) {
      if (Object.hasOwn(noticeOneData, imageCSSName)) {
        imageCSS[imageCSSName] = {};
        const imageCSSNameValueArray = noticeOneData[imageCSSName]
          .replaceAll('"', "")
          .replaceAll("\n", "")
          .split("|");

        imageCSSNameValueArray.forEach((nameAndValue) => {
          if (!nameAndValue) return;
          const name = toCamelCase(
            nameAndValue.split(":")[0].replaceAll(" ", "")
          );
          let value = nameAndValue.split(":")[1].trim();
          imageCSS[imageCSSName][name] = value;
        });
      }
    }
  }
  ///////

  return (
    <div className={styles["noticeOne-container"]}>
      <a
        name="noticeOne"
        href="#noticeOne"
        className={styles["anchor-page-bookmark"]}
      >
        <span></span>
      </a>
      <div className={styles["noticeOne-text-container"]}>
        {(noticeOneData.imageOneURL || noticeOneData.title) && (
          <div className={styles["title-container"]}>
            {noticeOneData.imageOneURL && (
              <div className={styles["image-wrap"]}>
                <figure>
                  <img
                    src={noticeOneData.imageOneURL}
                    className={
                      styles["image"] +
                      " " +
                      styles["image-one"] +
                      " " +
                      styles["content"]
                    }
                    style={{ ...imageCSS.imageOneCSS }}
                    alt={noticeOneData.title + " first image "}
                  />
                  <figcaption>
                    <a
                      href={noticeOneData.imageOneAttributionURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {noticeOneData.imageOneAttribution}
                    </a>
                  </figcaption>
                </figure>
              </div>
            )}
            {noticeOneData.title && (
              <div className={styles["sub-title-background"]}>
                <h3 className={`sub-title ${styles["sub-title"]}`}>
                  {noticeOneData.title}
                </h3>
              </div>
            )}
          </div>
        )}
        {noticeOneData.text && (
          <p
            className={
              styles["text"] +
              " " +
              styles["text-one"] +
              " " +
              styles["content"]
            }
          >
            {determineAndCleanJSXOutput(textOne)}
          </p>
        )}{" "}
        {noticeOneData.link && (
          <div className={styles["link-wrap"]}>
            <a target="_blank" rel="noreferrer" href={noticeOneData.link}>
              {noticeOneData.link}
            </a>
          </div>
        )}
        {(noticeOneData.imageOneURL || noticeOneData.textTwo) && (
          <div
            className={
              styles["text-pic-container"] +
              " " +
              styles["text-pic-container-two"]
            }
          >
            {" "}
            {noticeOneData.imageTwoURL && (
              <div className={styles["image-wrap"]}>
                <figure>
                  <img
                    src={noticeOneData.imageTwoURL}
                    className={
                      styles["image"] +
                      " " +
                      styles["image-two"] +
                      " " +
                      styles["content"]
                    }
                    alt={noticeOneData.title + " second image "}
                    style={{ ...imageCSS.imageTwoCSS }}
                  />
                  <figcaption>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={noticeOneData.imageTwoAttributionURL}
                    >
                      {noticeOneData.imageTwoAttribution}
                    </a>
                  </figcaption>
                </figure>
              </div>
            )}
            {noticeOneData.textTwo && (
              <p
                className={
                  styles["text"] +
                  " " +
                  styles["text-two"] +
                  " " +
                  styles["content"]
                }
              >
                {determineAndCleanJSXOutput(textTwo)}
              </p>
            )}{" "}
          </div>
        )}
        {(noticeOneData.imageThreeURL || noticeOneData.textThree) && (
          <div
            className={
              styles["text-pic-container"] +
              " " +
              styles["text-pic-container-three"]
            }
          >
            {noticeOneData.textThree && (
              <p
                className={
                  styles["text"] +
                  " " +
                  styles["text-three"] +
                  " " +
                  styles["content"]
                }
              >
                {determineAndCleanJSXOutput(textThree)}
              </p>
            )}
            {noticeOneData.imageThreeURL && (
              <div className={styles["image-wrap"]}>
                <figure>
                  <img
                    src={noticeOneData.imageThreeURL}
                    className={
                      styles["image"] +
                      " " +
                      styles["image-three"] +
                      " " +
                      styles["content"]
                    }
                    alt={noticeOneData.title + " third image "}
                    style={{ ...imageCSS.imageThreeCSS }}
                  />
                  <figcaption>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={noticeOneData.imageThreeAttributionURL}
                    >
                      {noticeOneData.imageThreeAttribution}
                    </a>
                  </figcaption>
                </figure>
              </div>
            )}
          </div>
        )}
        {(noticeOneData.imageFourURL || noticeOneData.textFour) && (
          <div
            className={
              styles["text-pic-container"] +
              " " +
              styles["text-pic-container-four"]
            }
          >
            {" "}
            {noticeOneData.textFour && (
              <p
                className={
                  styles["text"] +
                  " " +
                  styles["text-four"] +
                  " " +
                  styles["content"]
                }
              >
                {determineAndCleanJSXOutput(textFour)}
              </p>
            )}
            {noticeOneData.imageFourURL && (
              <div className={styles["image-wrap"]}>
                {" "}
                <figure>
                  <img
                    src={noticeOneData.imageFourURL}
                    className={
                      styles["image"] +
                      " " +
                      styles["image-four"] +
                      " " +
                      styles["content"]
                    }
                    alt={noticeOneData.title + " fourth image "}
                    style={{ ...imageCSS.imageFourCSS }}
                  />
                  <figcaption>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={noticeOneData.imageFourAttributionURL}
                    >
                      {noticeOneData.imageFourAttribution}
                    </a>
                  </figcaption>
                </figure>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoticeOne;
