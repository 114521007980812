// const colors = ["red", "green", "blue", "orange", "violet"];
// const formats = [
//   [
//     {
//       className: "ql-font",
//       options: ["serif", "monospace"],
//     },
//     {
//       className: "ql-size",
//       options: ["small", "large", "huge"],
//     },
//   ],
//   [
//     { className: "ql-bold" },
//     { className: "ql-italic" },
//     { className: "ql-underline" },
//     { className: "ql-strike" },
//   ],
//   [
//     {
//       className: "ql-color",
//       options: colors,
//     },
//     {
//       className: "ql-background",
//       options: colors,
//     },
//   ],
//   [
//     {
//       className: "ql-script",
//       value: "sub",
//     },
//     {
//       className: "ql-script",
//       value: "super",
//     },
//   ],
//   [
//     {
//       className: "ql-header",
//       value: "1",
//     },
//     {
//       className: "ql-header",
//       value: "2",
//     },
//     {
//       className: "ql-blockquote",
//     },
//     {
//       className: "ql-code-block",
//     },
//   ],
//   [
//     {
//       className: "ql-list",
//       value: "ordered",
//     },
//     {
//       className: "ql-list",
//       value: "bullet",
//     },
//     {
//       className: "ql-indent",
//       value: "-1",
//     },
//     {
//       className: "ql-indent",
//       value: "+1",
//     },
//   ],
//   [
//     {
//       className: "ql-direction",
//       value: "rtl",
//     },
//     {
//       className: "ql-align",
//       options: ["right", "center", "justify"],
//     },
//   ],
//   [
//     { className: "ql-link" },
//     { className: "ql-image" },
//     { className: "ql-video" },
//     { className: "ql-formula" },
//   ],
// ];
export const colors = [
  "-THEME COLORS-",
  "#ff4024",
  "#c92e7d",
  "#bb2b95",
  "#470d28",
  "#0e0db4",
  "#7575f5",
  "#000000",
  "#ffffff",
  "#181616",
  "#2d3335",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-SPACER-",
  "-OTHER COLORS-",
  "#ffffff",
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",

  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "background",
  "color",
  "emoji",
  "video",
  "script",
];
