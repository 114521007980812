const useAddToBackgroundColorCSS = () => {
  const outputFunction = (arrayOfStrings) => {
    const outputArray = [];
    arrayOfStrings.forEach((string) => {
      const output =
        typeof string === "string"
          ? string.replaceAll(
              "background-color",
              "background-image: none;  background-color"
            )
          : string;

      outputArray.push(output);
    });
    return outputArray;
  };
  return outputFunction;
};

export default useAddToBackgroundColorCSS;
