import styles from "./NoticeTwo.module.css";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { toCamelCase } from "../../Hooks/utility";
import useDetermineAndCleanJSXOutput from "../../Hooks/useDetermineAndCleanJSXOutput";
import useAddToBackgroundColorCSS from "../../Hooks/useAddToBackgroundColorCSS";
import useShortCodes from "../../Hooks/useShortCodes";
import useItemIsUsed from "../../Hooks/useItemIsUsed";

const NoticeTwo = () => {
  const { content } = useSelector((state) => state.contentData);
  const [noticeTwoData, setNoticeTwoData] = useState({});
  const shortCodes = useShortCodes();
  const itemIsUsed = useItemIsUsed();
  const textOne = itemIsUsed(noticeTwoData.text)
    ? shortCodes({ text: noticeTwoData.text, styles })
    : null;
  const textTwo = itemIsUsed(noticeTwoData.textTwo)
    ? shortCodes({ text: noticeTwoData.textTwo, styles })
    : null;

  const textThree = itemIsUsed(noticeTwoData.textThree)
    ? shortCodes({ text: noticeTwoData.textThree, styles })
    : null;

  const textFour = itemIsUsed(noticeTwoData.textFour)
    ? shortCodes({ text: noticeTwoData.textFour, styles })
    : null;

  const determineAndCleanJSXOutput = useDetermineAndCleanJSXOutput();
  const addToBackgroundColorCSS = useAddToBackgroundColorCSS();

  useEffect(() => {
    if (content) {
      Object.keys(content).forEach((key) =>
        Object.entries(content[key]).forEach((entry) => {
          const test =
            entry[0].includes("type") && entry[1].includes("noticeTwo");
          if (test) setNoticeTwoData(content[key]);
        }),
      );
    }
  }, [content]);

  // Assemble user-supplied image css
  const imageCSSNamesArray = [
    "imageOneCSS",
    "imageTwoCSS",
    "imageThreeCSS",
    "imageFourCSS",
  ];
  const imageCSS = [];

  if (noticeTwoData) {
    for (const imageCSSName of imageCSSNamesArray) {
      if (Object.hasOwn(noticeTwoData, imageCSSName)) {
        imageCSS[imageCSSName] = {};
        const imageCSSNameValueArray = noticeTwoData[imageCSSName]
          .replaceAll('"', "")
          .replaceAll("\n", "")
          .split("|");

        imageCSSNameValueArray.forEach((nameAndValue) => {
          if (!nameAndValue) return;
          const name = toCamelCase(
            nameAndValue.split(":")[0].replaceAll(" ", ""),
          );

          let value = nameAndValue.split(":")[1].trim();
          imageCSS[imageCSSName][name] = value;
        });
      }
    }
  }
  ///////

  return (
    <div className={styles["noticeTwo-container"]}>
      <a
        name="noticeTwo"
        href="#noticeTwo"
        className={styles["anchor-page-bookmark"]}
      >
        <span></span>
      </a>
      <div className={styles["background-video-wrap"]}>
        <div className={styles["bubble"]}></div>
        <div className={styles["bubble"]}></div>
        <div className={styles["bubble"]}></div>
        <div className={styles["bubble"]}></div>
        <div className={styles["bubble"]}></div>
        <div className={styles["bubble"]}></div>
      </div>
      {
        // ******* Set ONE *******
      }
      {(noticeTwoData.imageOneURL ||
        (noticeTwoData.title && textOne) ||
        textOne) && (
        <div
          className={
            styles["noticeTwo-fixed-image-container"] + " " + styles["imageOne"]
          }
          style={{ backgroundImage: `url(${noticeTwoData.imageOneURL})` }}
        >
          <div className={styles["title-container"]}>
            {noticeTwoData.title ||
              (textOne && (
                <div className={styles["sub-title-background"]}>
                  {noticeTwoData.title && (
                    <h3 className={`sub-title ${styles["sub-title"]}`}>
                      {noticeTwoData.title}
                    </h3>
                  )}
                  {textOne && (
                    <p
                      className={
                        styles["text"] +
                        " " +
                        styles["text-one"] +
                        " " +
                        styles["content"]
                      }
                    >
                      {determineAndCleanJSXOutput(
                        addToBackgroundColorCSS(textOne),
                      )}
                    </p>
                  )}
                </div>
              ))}
            {noticeTwoData.imageTwoAttributionURL &&
              noticeTwoData.iimageOneAttribution && (
                <figcaption>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={noticeTwoData.iimageOneAttributionURL}
                  >
                    {noticeTwoData.iimageOneAttribution && (
                      <span>
                        Background image by {noticeTwoData.iimageOneAttribution}
                      </span>
                    )}
                    {!noticeTwoData.iimageOneAttribution && (
                      <span>Background image Info &rarr;</span>
                    )}
                  </a>
                </figcaption>
              )}
            {!noticeTwoData.iimageOneAttributionURL &&
              noticeTwoData.iimageOneAttribution && (
                <figcaption>
                  <span>
                    Background image by {noticeTwoData.iimageOneAttribution}
                  </span>
                </figcaption>
              )}
          </div>{" "}
        </div>
      )}
      {
        // ******* Set Two *******
      }
      {(noticeTwoData.imageTwoURL || textTwo) && (
        <div
          className={
            styles["noticeTwo-container"] +
            " " +
            styles["noticeTwo-text-container"]
          }
        >
          {(noticeTwoData.imageTwoURL || textTwo) && (
            <div
              className={
                styles["text-pic-container"] +
                " " +
                styles["text-pic-container-two"]
              }
            >
              {noticeTwoData.imageTwoURL && (
                <div className={styles["image-wrap"]}>
                  <figure>
                    <img
                      src={noticeTwoData.imageTwoURL}
                      className={
                        styles["image"] +
                        " " +
                        styles["image-two"] +
                        " " +
                        styles["content"]
                      }
                      alt={textTwo + " second image "}
                      style={{ ...imageCSS.imageTwoCSS }}
                    />
                    {noticeTwoData.imageTwoAttributionURL &&
                      noticeTwoData.imageTwoAttribution && (
                        <figcaption>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={noticeTwoData.imageTwoAttributionURL}
                          >
                            {noticeTwoData.imageTwoAttribution && (
                              <span>
                                Background image by{" "}
                                {noticeTwoData.imageTwoAttribution}
                              </span>
                            )}
                            {!noticeTwoData.imageTwoAttribution && (
                              <span>Background image Info &rarr;</span>
                            )}
                          </a>
                        </figcaption>
                      )}
                    {!noticeTwoData.imageTwoAttributionURL &&
                      noticeTwoData.imageTwoAttribution && (
                        <figcaption>
                          <span>
                            Background image by{" "}
                            {noticeTwoData.imageTwoAttribution}
                          </span>
                        </figcaption>
                      )}
                  </figure>
                </div>
              )}
              {textTwo && (
                <p
                  className={
                    styles["text"] +
                    " " +
                    styles["text-two"] +
                    " " +
                    styles["content"]
                  }
                >
                  {determineAndCleanJSXOutput(textTwo)}
                </p>
              )}
            </div>
          )}
        </div>
      )}
      {
        // ******* Set Three *******
      }
      {(noticeTwoData.imageThreeURL ||
        (noticeTwoData.title && textThree) ||
        textThree) && (
        <Fragment>
          {
            // Spacer if used with a preceding element
            (noticeTwoData.imageOneURL ||
              (noticeTwoData.title && textOne) ||
              textOne ||
              noticeTwoData.imageTwoURL ||
              (noticeTwoData.title && textTwo) ||
              textTwo) && <div className={styles["spacer-3"]}></div>
          }
          <div
            className={
              styles["noticeTwo-fixed-image-container"] +
              " " +
              styles["text-image-three-container"]
            }
            style={{
              backgroundImage: `url(${noticeTwoData.imageThreeURL}) !important`,
            }}
          >
            <div className={styles["title-container"]}>
              {noticeTwoData.title ||
                (textThree && (
                  <div className={styles["sub-title-background"]}>
                    {noticeTwoData.title && (
                      <h3 className={`sub-title ${styles["sub-title"]}`}>
                        {noticeTwoData.title}
                      </h3>
                    )}
                    {textThree && (
                      <p
                        className={
                          styles["text"] +
                          " " +
                          styles["text-three"] +
                          " " +
                          styles["content"]
                        }
                      >
                        {determineAndCleanJSXOutput(
                          addToBackgroundColorCSS(textThree),
                        )}
                      </p>
                    )}
                  </div>
                ))}
              {noticeTwoData.imageThreeAttributionURL &&
                noticeTwoData.imageThreeAttribution && (
                  <figcaption>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={noticeTwoData.imageThreeAttributionURL}
                    >
                      {noticeTwoData.imageThreeAttribution && (
                        <span>
                          Background image by{" "}
                          {noticeTwoData.imageThreeAttribution}
                        </span>
                      )}
                      {!noticeTwoData.imageThreeAttribution && (
                        <span>Background image Info &rarr;</span>
                      )}
                    </a>
                  </figcaption>
                )}
              {!noticeTwoData.imageThreeAttributionURL &&
                noticeTwoData.imageThreeAttribution && (
                  <figcaption>
                    <span>
                      Background image by {noticeTwoData.imageThreeAttribution}
                    </span>
                  </figcaption>
                )}
            </div>
          </div>
        </Fragment>
      )}
      {
        // ******* Set Four *******
      }
      {(noticeTwoData.imageFourURL ||
        (noticeTwoData.title && textFour) ||
        textFour) && (
        <div
          className={
            styles["noticeTwo-fixed-image-container"] +
            " " +
            styles["text-image-four-container"]
          }
        >
          {" "}
          <div className={styles["title-container"]}>
            {noticeTwoData.imageFourURL && (
              <div className={styles["sub-title-background"]}>
                {noticeTwoData.imageFourURL && (
                  <div className={styles["image-wrap"]}>
                    <figure>
                      <img
                        src={noticeTwoData.imageFourURL}
                        className={
                          styles["image"] +
                          " " +
                          styles["image-two"] +
                          " " +
                          styles["content"]
                        }
                        alt={textTwo + " second image "}
                        style={{ ...imageCSS.imageFourCSS }}
                      />
                    </figure>
                  </div>
                )}
                {textFour && (
                  <div
                    className={
                      styles["text"] +
                      " " +
                      styles["text-four"] +
                      " " +
                      styles["content"]
                    }
                  >
                    {determineAndCleanJSXOutput(
                      addToBackgroundColorCSS(textFour),
                    )}
                  </div>
                )}
                {noticeTwoData.imageFourAttributionURL &&
                  noticeTwoData.imageFourAttribution && (
                    <figcaption>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={noticeTwoData.imageFourAttributionURL}
                      >
                        {noticeTwoData.imageFourAttribution && (
                          <span>
                            Background image by{" "}
                            {noticeTwoData.imageFourAttribution}
                          </span>
                        )}
                        {!noticeTwoData.imageFourAttribution && (
                          <span>Background image Info &rarr;</span>
                        )}
                      </a>
                    </figcaption>
                  )}
                {!noticeTwoData.imageFourAttributionURL &&
                  noticeTwoData.imageFourAttribution && (
                    <figcaption>
                      <span>
                        Background image by {noticeTwoData.imageFourAttribution}
                      </span>
                    </figcaption>
                  )}
              </div>
            )}
          </div>
        </div>
      )}{" "}
    </div>
  );
};

export default NoticeTwo;
