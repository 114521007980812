// import { useState } from "react";
import ReactQuill from "react-quill";
// import quillEmoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
// import PropTypes from "prop-types";
import { colors, formats } from "./ToolbarOptions";
/*
 * Simple editor component that takes placeholder text as a prop
 */
/*
 * Simple editor component that takes placeholder text as a prop
 */
const Editor = (props) => {
  // const [editorHtml, setEditorHtml] = useState("");

  // const [theme, setTheme] = useState("snow");
  const theme = "snow";
  // const handleChange = (html) => {
  //   setEditorHtml(html);
  // };

  // const handleThemeChange = (newTheme) => {
  //   if (newTheme === "core") newTheme = null;
  //   setTheme(newTheme);
  // };

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: "3" },
        { header: [1, 2, 3, 4, 5, 6, false] },
        { size: [] },
        { font: [] },
      ],
      [{ background: colors }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */

  return (
    <div>
      <ReactQuill
        theme={theme}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        modules={modules}
        formats={formats}
        bounds={".app"}
        placeholder={props.placeholder}
      />

      {
        //  <div className="themeSwitcher">
        //       <label>Theme </label>
        //       <select onChange={(e) => handleThemeChange(e.target.value)}>
        //         <option value="snow">Snow</option>
        //         <option value="bubble">Bubble</option>
        //         <option value="core">Core</option>
        //       </select>
        //     </div>
      }
    </div>
  );
};
export default Editor;
